<template>
  <div class="con">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="switch">
        <div class="switch-item">
          <span>充值金额</span>
          <div class="s-i-field">
            <input v-if="platform_name == 'android'" v-model="money" class="face-price-input mg-rt20" readonly @click="openKeyboard('money')" />
            <input v-else v-model="money" type="number" class="face-price-input mg-rt20" />
            <span>元</span>
          </div>
        </div>
        <div class="switch-item">
          <span>赠送类型</span>
          <van-radio-group v-model="type" direction="horizontal" :disabled="shop.user_model != 1">
            <van-radio name="1">会员余额</van-radio>
            <van-radio name="2">优惠券</van-radio>
          </van-radio-group>
        </div>
        <div class="switch-item" v-if="type == 1">
          <span>赠送</span>
          <div class="s-i-field">
            <input v-if="platform_name == 'android'" v-model="give_money" class="face-price-input mg-rt20" readonly @click="openKeyboard('give_money')" />
            <input v-else v-model="give_money" type="number" class="face-price-input mg-rt20" />
            <span>元</span>
          </div>
        </div>
        <div class="switch-item" v-if="type == 2">
          <span>优惠券</span>
          <div class="s-i-field">
            <button class="ff-chaoose-btn" @click="openCouponModal">选择优惠券</button>
          </div>
        </div>
        <ul v-if="choosed_coupon.length > 0">
          <li class="c-c-head">
            <div class="color-333 ft-22 text-center ft-bold">优惠券名称</div>
            <div class="color-333 ft-22 text-center ft-bold">数量</div>
            <div class="color-333 ft-22 text-center ft-bold">操作</div>
          </li>
          <li class="c-c-body" v-for="(item, index) in choosed_coupon">
            <div class="color-333 ft-22">{{ item.name }}</div>
            <div class="color-333 ft-22">
              <input v-if="platform_name == 'android'" class="stock-input" v-model="item.coupon_num" placeholder="请输入" readonly @click="openKeyboardCoupon(item, index)" />
              <input v-else class="stock-input" v-model="item.coupon_num" placeholder="请输入" type="number" />
            </div>
            <div><van-icon name="delete-o" color="#ee0a24" size="3.2rem" @click.stop="deleteThatCoupon(item, index)" /></div>
          </li>
        </ul>
      </div>
      <div style="height: 10rem;"></div>
      <div class="foot_btn_box" v-if="shop.user_model == 1">
        <van-button v-if="id" class="foot_btn" style="margin-right: 10rem; background: #e60012; color: #fff;" @click="openDialog()">删除</van-button>
        <van-button v-else class="foot_btn" style="margin-right: 10rem;" @click="goBack">取消</van-button>
        <van-button class="foot_btn foot_submit" @click="onSubmit()">保存</van-button>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>

    <van-number-keyboard 
      v-model="numVal" 
      :title="numVal" 
      :show="show_keyboard" 
      extra-key="." 
      @blur="show_keyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete"
      class="otherEatNum-nk" 
    />

    <van-number-keyboard 
      v-model="couponNumVal" 
      :title="couponNumVal" 
      :show="show_keyboard_num" 
      @blur="show_keyboard_num = false;" 
      @input="handleCouponNumChange" 
      @delete="handleCouponNumDelete"
      class="otherEatNum-nk" 
    />

    <!-- 选择优惠券 开始 -->
    <div class="choose-coupon-modal" v-if="show_choose_coupon">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeCouponModal" />

        <div class="wrapper">
          <!-- 标题 -->
          <div class="title color-333 ft-28 flex flex-ac flex-jc ft-bold' pd-bt25">选择优惠券</div>

          <div class="mocontent">
            <div class="deskcontent">
              <div class="index_list">
                <div v-for="(item, i) in modal_coupon_list" :key="i" class="index_box relative" @click="chooseThatCoupon(item)">
                  <van-checkbox class="del-checkbox" icon-size="2.6rem" :value="item.delChoosed" shape="square" />
                  <div class="coupon-left">
                    <div class="color-1588F5 mg-bt15">
                      <span class="mg-rt5 ft-40 ft-bold">{{ item.face }}</span>
                      <span class="ft-22" v-if="item.type == 1" style="vertical-align: super;">元</span>
                      <span class="ft-22" v-if="item.type == 2" style="vertical-align: super;">折</span>
                    </div>
                    <div class="ft-22 color-333">满{{ item.cill }}可用</div>
                  </div>
                  <div style="flex: 1;" class="pd-lt20 pd-rt5">
                    <div class="ft-22 color-333 mg-bt25">{{ item.name }}</div>
                    <div v-if="item.period == 1" class="ft-20 color-666">领券后{{ item.day }}天内有效</div>
                    <div v-if="item.period == 2" class="ft-20 color-666">有效期：{{ item.start_time }} 至 {{ item.end_time }}</div>
                    <div v-if="item.period == 3" class="ft-20 color-666">有效期：长期有效</div>
                  </div>
                </div>
              </div>
              <div class="flex mg-tp10 mg-bt10 mg-rt20" style="justify-content:flex-end;">
                <div class="pagination" v-if="m_count > 0">
                  <van-pagination v-model="m_page" :total-items="m_count" :items-per-page="m_limit" mode="simple" force-ellipses
                    @change="handleChangeOfModal" style="background-color: #f2f2f2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeCouponModal">取消</button>
          <button class="confirm " @click="confirmCouponModal()">确认</button>
        </div>
      </div>
    </div>
    <!-- 选择优惠券 结束 -->
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
import { datetimePickerFormatter, formatTime } from '../../utils/util';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  name: "addWorker",
  components: {
    titlebar,
    popupOfDialog,
  },
  data() {
    return {
      money: "", // 充值金额
      type: '1', // 类型： 1会员余额 2优惠券
      give_money: "", // 赠送金额
      id: '',
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
      numberModel: '', // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
      show_choose_coupon: false,
      select_coupon: [], // 选中的优惠券
      choosed_coupon: [], // 已选择的优惠券
      modal_coupon_list: [], // 弹窗内的优惠券数据
      m_page: 1, // 当前页数
      m_limit: 6,
      m_count: 0,
      couponNumVal: '',
      couponNumValArr: [],
      show_keyboard_num: false,
      numberIndex: -1,
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'})
  },
  async created() {
    this.choosed_coupon = [];
    this.page_name = this.$route.query.name || '';
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.setInfo();
    }
  },
  methods: {
    handleCouponNumChange(e) {
			this.couponNumValArr.push(e);
			this.couponNumValArr = this.couponNumValArr.join('');
			this.choosed_coupon[this.numberIndex].coupon_num = this.couponNumValArr;
      console.log('this.choosed_coupon', this.choosed_coupon);
		},
		handleCouponNumDelete(e) {
			this.couponNumValArr.pop();
			this.numVal = this.couponNumValArr.length > 0 ? this.couponNumValArr.join('') : '';
			this.choosed_coupon[this.numberIndex].coupon_num = this.couponNumValArr;
		},
    openKeyboardCoupon(item, index) {
      this.numberIndex = index;
      this.couponNumVal = item.coupon_num.toString() || '';
      this.couponNumValArr = item.coupon_num.toString().split('');
      this.show_keyboard_num = true;
    },
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this[`${this.numberModel}`] = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this[`${this.numberModel}`] = this.numVal;
		},
    openKeyboard(name) {
      console.log(this[`${name}`]);
      this.numberModel = name;
      this.numVal = this[`${name}`] || '';
      this.numValArr = this[`${name}`].split('');
      this.show_keyboard = true;
    },
    delScheme() {
      this.$api.deleteRechangeActivity(this.id).then(res => {
        if (res.code == 1) {
          this.$toast('删除成功');
          this.goBack();
        }
      });
    },
    confirmPopDialog() {
      this.delScheme();
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = {};
      this.showDialog = true;
    },
    setInfo() {
      this.$api.getRechangeActivityInfo(this.id).then(res => {
        if (res.code == 1) {
          this.money = res.data.money.toString();
          this.type = res.data.type.toString();
          this.give_money = res.data.give_money.toString();
          this.choosed_coupon = res.data.coupon || [];
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'recharge-scheme',
        query: {
          name: 'recharge-scheme',
          active: this.$route.query.active,
        },
      })
    },
    // 提交
    onSubmit(e) {
      if (this.money == '' || !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.money)) {
        this.$toast('充值金额不允许为0，最多2位小数');
        return;
      }
      if (this.type == 1 && (this.give_money == '' || !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.give_money))) {
        this.$toast('赠送金额不允许为0，最多2位小数');
        return;
      }
      let data = {
        money: this.money,
        type: this.type, // 类型1金额 2优惠券
      };
      if (this.type == 1) {
        data['give_money'] = this.give_money;
      }
      if (this.type == 2) {
        if (this.choosed_coupon.length < 1) {
          this.$toast('请选择优惠券');
          return;
        }
        let arr = [];
        for (let i = 0; i < this.choosed_coupon.length; i++) {
          const element = this.choosed_coupon[i];
          if (element.coupon_num > 10) {
            this.$toast('优惠券数量上限10张');
            return;
          }
          arr.push({
            id: element.id,
            num: element.coupon_num
          })
        }
        data['give_coupon'] = JSON.stringify(arr);
      }
      if (this.id) {
        data.id = this.id;
        this.editCoupon(data);
      } else {
        this.addCoupon(data);
      }
    },
    addCoupon(query) {
      this.$api.addRechangeActivity(query).then(res => {
        if (res.code == 1) {
          this.$toast(res.msg || '新增成功');
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    editCoupon(query) {
      this.$api.editRechangeActivity(this.id, query).then(res => {
        if (res.code == 1) {
          this.$toast('编辑成功');
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    chooseThatCoupon(item) {
      item.delChoosed ? item.delChoosed = false : this.$set(item, 'delChoosed', true);
      let arr = this.select_coupon;
      if (item.delChoosed) {
        arr.push(item);
      } else {
        const index = arr.findIndex(e => e.id == item.id);
        arr.splice(index, 1);
      }
      console.log('this.choosed_coupon 624', this.choosed_coupon);
    },
    deleteThatCoupon(item, index) {
      this.choosed_coupon.splice(index, 1);
    },
    async confirmCouponModal() {
      let arr = [];
      this.select_coupon.forEach(element => {
        arr.push(Object.assign({}, element, {
          coupon_num: element.coupon_num || 1,
        }))
      });
      this.choosed_coupon = arr;
      this.closeCouponModal();
    },
    closeCouponModal() {
      this.show_choose_coupon = false;
    },
    async openCouponModal() {
      this.m_page = 1;
      await this.getCouponList();
      this.setSelectData();
      console.log('this.choosed_coupon', this.choosed_coupon);
      let arr = [];
      this.choosed_coupon.forEach(element => {
        arr.push(element);
      });
      this.select_coupon = arr;
      this.show_choose_coupon = true;
    },
    setSelectData() {
      for (let i = 0; i < this.modal_coupon_list.length; i++) {
        const element = this.modal_coupon_list[i];
        const index = this.choosed_coupon.findIndex(e => e.id == element.id);
        if (index > -1) {
          element.delChoosed = true;
        }
      }
    },
    async handleChangeOfModal() {
      await this.getCouponList();
      this.setSelectData();
    },
    getCouponList(type) {
      this.modal_coupon_list = [];
      return new Promise((resolve, reject) => {
        let query = {}
        query = {
          page: this.m_page,
          limit: this.m_limit,
          hidden: 1, // 是否显示过期优惠券 0 显示 1不显示
        }
        this.$api.Coupon_getList(query).then(res => {
          if (res.code = 1) {
            const list = res.data.list || [];
            const count = res.data.count || 0;
            list.forEach(element => {
              if (element.period == 2) {
                element.start_time = formatTime(new Date(element.start_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.end_time), 'yyyy-MM-dd');
              }
            });
            this.modal_coupon_list = list || [];
            this.m_count = count || 0;
            resolve();
          } else {
            this.modal_coupon_list = [];
            this.$toast(res.msg);
            resolve();
          }
        })
      })
    },
  },
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.desk-right {
  height: calc(100% - 10.6rem);
  background-color: #fff;
  margin-top: 1.5rem;
  overflow-y: scroll;

  .switch {
    // margin-top: 0.2rem;
    background: #fff;
    padding-bottom: 2rem;

    .switch-item {
      font-size: 2.4rem;
      height: 9.5rem;
      background-color: #fff;
      // margin-top: 0.05rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.2rem;
      border-bottom: 0.15rem solid #eeeeee;
      width: calc(100% - 16.8rem);
      margin: 0 4rem;
    }

    .spt-btns {
      display: flex;
      margin: 5rem auto 2.5rem;
      justify-content: center;
    }

    .switchinput {
      // border: 0.01rem #eeeeee solid;
      border-radius: 0.8rem;
      height: 70%;
      width: 80%;
    }

    .s-i-field {
      width: 80%;
      text-align: left;
    }

    /deep/.van-radio-group {
      height: 100%;
      width: 80%;
    }

    .ff-chaoose-btn {
      width: 16rem;
      height: 6.2rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      color: #fff;
      background: #1588F5;
    }

    .c-c-head {
      background: #f2f2f2;
      height: 7.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        flex: 1;
      }
    }

    .c-c-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        flex: 1;
        border-bottom: 0.15rem rgba(0, 0, 0, 0.06) solid;
        height: 7.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .stock-input {
        width: 40%;
        height: 6rem;
        border: 0.2rem solid #DEDEDE;
        border-radius: 0.8rem;
        padding: 0 2rem;
        font-size: 2.4rem;
      }
    }
  }

  .foot_btn_box {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    z-index: 10;
    height: 10rem;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    left: 0;

    .foot_btn {
      font-size: 2.4rem;
      width: 42rem;
      height: 7rem;
      border-radius: 0.8rem;
      background: #eaeaea;
    }

    .foot_submit {
      color: #fff;
      background-color: #1588f5;
    }
  }

  .add-food {
    height: 100%;
    margin-top: 2rem;
    overflow: scroll;
    overflow-x: hidden;

    .van-cell.van-field {
      font-size: 2.6rem;
      padding: 2rem 3rem;

      /deep/.van-cell__title span {
        line-height: 6rem;
      }

      /deep/.van-field__control {
        min-height: 6rem;

        .van-radio__icon {
          font-size: 2.6rem;
        }

        .van-radio__icon--checked {
          font-size: 2.6rem;

          .van-icon {
            background-color: #1588F5;
            border-color: #1588F5;
          }
        }
      }
    }

    .van-cell.van-field {
      font-size: 2.6rem;

      /deep/textarea.van-field__control::placeholder {
        transform: translateY(0.1rem);
      }

      /deep/.van-field__word-limit {
        font-size: 2.4rem;
      }
    }

    .van-button--normal {
      font-size: 2.6rem;
      background-color: #1588F5;
      border-color: #1588F5;
    }
  }
}

.editdesk-btn {
  width: 42rem;
  height: 8rem;
  border-radius: 0.8rem;
  border: 0;
}

.con {
  height: 100%;
}

.face-price-input {
  width: 11rem;
  height: 6.2rem;
  border: 0.2rem solid #DEDEDE;
  border-radius: 0.8rem;
  padding: 0 2rem;
  font-size: 2.4rem;
}

.choose-coupon-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
	
	.modal-content-box {
		width: 66%;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;
	
		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}

    .mocontent {
      margin: 2rem 0 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      height: 58vh;
      .deskcontent {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .del-checkbox {
          position: absolute;
          left: 0;
          top: 0;
          background: #fff;
        }
      }
    }
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
}

.index_list {
  width: calc(100% - 4rem);
  // height: 50rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(auto-fill, 15rem);
  grid-gap: 2.5rem 3%;
  overflow-x: hidden;
  overflow-y: scroll;

  .addpro-index-box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-size: 2.8rem;
    color: #1588F5;
    font-weight: bold;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  .index_box {
    position: relative;
    // width: 32.9rem;
    // height: 16rem;
    background: #ffffff;
    // box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: 0.5rem 1.5rem;
    height: 15rem;
    border: 0.2rem #cdcdcd solid;
    border-radius: 0.8rem;

    .coupon-tag-img {
      position: absolute;
      top: -1.2rem;
      right: 0;
      width: 11rem;
      height: 11rem;
    }

    .coupon-left {
      width: 23%;
      position: relative;
    }
    .coupon-left::after {
      content: '';
      width: 0.15rem;
      height: 132%;
      position: absolute;
      right: 0;
      top: -15%;
      border-right: 0.15rem dashed #E5E5E5;
    }
  }
  .expired-box {
    div {
      color: #999;
    }
  }
}

.pagination {
  width: 30%;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  /deep/.van-pagination__item--active {
    background-color: #1588F5;
  }
}
</style>
